<template>
  <div v-if="errorMessage !== null">
    <div class="container">
      <h1 class="review-title">
        {{ errorMessage }}
      </h1>
    </div>
  </div>
  <div v-else-if="exists === false">
    <div class="container">
      <div v-if="!confirmedRating">
        <div v-if="!isLockerOrder">
          <h1 class="review-title">Αξιολόγησε την εμπειρία σου μαζί μας.</h1>
          <h2 class="star-title">• Πώς ήταν η διανομή σου;</h2>
          <div @mouseleave="onRatingHover(null)" style="display: inline-block">
            <star-rating
              class="star-rating"
              :show-rating="false"
              :rounded-corners="true"
              :star-size="58"
              active-color="#f68b24"
              @current-rating="onRatingHover"
              @rating-selected="setRating"
            ></star-rating>
          </div>
          <p class="rating-explanation" v-if="hoveredRating !== null">
            {{ ratingExplanation[hoveredRating] }}
          </p>
          <p class="rating-explanation" v-else-if="rating !== null">
            {{ ratingExplanation[rating] }}
          </p>
          <div class="button-container">
            <button
              v-on:click="confirmRating"
              :disabled="isDisabled"
              class="button-next"
              type="button"
              value="submit"
            >
              <Span>Επόμενο</Span>
            </button>
          </div>
        </div>
        <div v-else>
          <h1 class="review-title">Αξιολόγησε την εμπειρία σου μαζί μας.</h1>
          <h2 class="star-title">
            • Πόσο πιθανό είναι να προτείνεις<br />τα
            <span class="bold-text">Skroutz Points</span> σε έναν φίλο <br />
            ή συνεργάτη;
          </h2>
          <br />
          <br />
          <br />
          <br />
          <br v-if="!isLargeScreen" />
          <br v-if="!isLargeScreen" />
          <br v-if="!isLargeScreen" />

          <div @mouseleave="onRatingHover(null)" style="display: inline-block">
            <div class="rating-container">
              <div
                class="circle"
                v-for="value in ratingScale"
                :key="value"
                :data-value="value"
                @mouseover="onRatingHover(value)"
                @click="setRating(value)"
                :class="{ selected: rating !== null && rating >= value }"
              >
                {{ value }}
              </div>
            </div>
          </div>
          <p class="rating-explanation" v-if="hoveredRating !== null">
            {{ ratingExplanation[hoveredRating] }}
          </p>
          <p class="rating-explanation" v-else-if="rating !== null">
            {{ ratingExplanation[rating] }}
          </p>
          <div class="button-container">
            <button
              v-on:click="confirmRating"
              :disabled="isDisabled"
              class="button-next"
              type="button"
              value="submit"
            >
              <Span>Επόμενο</Span>
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="!isLockerOrder">
          <div v-if="confirmedTipSelection === true">
            <h1 class="review-title">Ευχαριστούμε για την αξιολόγηση σου!</h1>
            <router-link to="/"
              ><button
                class="button-next"
                type="button"
                value="submit"
                style="background-color: #f68b24"
              >
                <Span>Επιστροφή</Span>
              </button></router-link
            >
          </div>
          <div v-else-if="rating !== null && rating > 3">
            <h1 class="review-title">
              Θα ήθελες να αφήσεις φιλοδώρημα στο διανομέα;
            </h1>
            <a
              :href="'https://www.skroutz.gr/tip/' + voucher"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                v-on:click="confirmTipSelection"
                :disabled="isDisabled"
                class="button-next"
                type="button"
                value="submit"
              >
                <Span>Ναι</Span>
              </button>
            </a>
            <button
              v-on:click="confirmTipSelection"
              :disabled="isDisabled"
              class="button-no"
              type="button"
              value="submit"
            >
              <Span>Όχι</Span>
            </button>

            <div style="margin-top: 15px">
              <h6>
                * Σιγουρέψου οτι έχεις συνδεθεί στον λογαριασμό απο τον οποίον ολοκλήρωσες την
                παραγγελία για να αφήσεις φιλοδώρημα!
              </h6>
            </div>
          </div>
          <div v-else-if="rating !== null && rating <= 3">
            <div v-if="reviewFeedback === null">
              <h1 class="review-title">
                Ζητάμε συγνώμη για την εμπειρία που είχες μαζί μας.
              </h1>
              <h2 class="star-title">• Τι θα μας πρότεινες να βελτιώσουμε;</h2>
              <div>
                <button
                  v-on:click="onFeedback('delivery time')"
                  class="button-next"
                  type="button"
                  value="delivery-time"
                  style="background-color: #f68b24"
                >
                  <Span>Χρόνος Παράδοσης</Span>
                </button>
                <button
                  v-on:click="onFeedback('delivery location')"
                  class="button-next"
                  type="button"
                  value="delivery-location"
                  style="background-color: #f68b24"
                >
                  <Span style="font-size: 1.2rem; line-height: 19.35px !important"
                    >Δεν έγινε παράδοση στην πόρτα μου</Span
                  >
                </button>
              </div>
              <div>
                <button
                  v-on:click="onFeedback('driver conduct')"
                  class="button-next"
                  type="button"
                  value="driver-conduct"
                  style="background-color: #f68b24"
                >
                  <Span>Συμπεριφορά Διανομέα</Span>
                </button>
                <button
                  v-on:click="onFeedback('custom')"
                  class="button-next"
                  type="button"
                  value="custom"
                  style="background-color: #f68b24"
                >
                  <Span>Πρόσθεσε Σχόλιο</Span>
                </button>
              </div>
            </div>
            <div v-else-if="reviewFeedback === 'custom'">
              <h1 class="review-title">
                Ζητάμε συγνώμη για την εμπειρία που είχες μαζί μας.
              </h1>
              <h2 class="star-title">• Τι θα μας πρότεινες να βελτιώσουμε;</h2>
              <textarea
                autofocus
                maxlength="500"
                v-model="feedbackMessage"
                placeholder="Στείλε μας τις παρατηρήσεις σου"
              ></textarea>
              <div>
                <button
                  v-on:click="onFeedback(feedbackMessage)"
                  class="button-next"
                  :disabled="isDisabledSend"
                  type="button"
                  value="submit"
                >
                  <Span>Αποστολή</Span>
                </button>
              </div>
            </div>
            <div v-else-if="confirmedSend === true">
              <div class="container">
                <h1 class="review-title">
                  Ευχαριστούμε για την αξιολόγηση σου!
                </h1>
                <router-link to="/"
                  ><button
                    class="button-next"
                    type="button"
                    value="submit"
                    style="background-color: #f68b24"
                  >
                    <Span>Επιστροφή</Span>
                  </button></router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="confirmedSend === true">
            <h1 class="review-title">Ευχαριστούμε πολύ για το χρόνο σου!</h1>
            <router-link to="/"
              ><button
                class="button-next"
                type="button"
                value="submit"
                style="background-color: #f68b24"
              >
                <Span>Επιστροφή</Span>
              </button></router-link
            >
          </div>
          <div v-else-if="rating !== null && rating >= 9">
            <div v-if="reviewFeedback === null">
              <h1 class="review-title">
                Χαιρόμαστε που είχες ευχάριστη εμπειρία.
              </h1>
              <h2 v-if="isLargeScreen" class="star-title">
                {{
                  isReturn === true
                    ? '• Τι σου άρεσε περισσότερο από την επιστροφή μέσω Skroutz Point;'
                    : '• Τι σου άρεσε περισσότερο από την παραλαβή μέσω Skroutz Point;'
                }}
              </h2>
              <h2 v-else class="star-title">
                • Τι σου άρεσε περισσότερο από <br />
                {{
                  isReturn === true
                    ? 'την επιστροφή μέσω Skroutz Point;'
                    : 'την παραλαβή μέσω Skroutz Point;'
                }}
              </h2>
              <br />
              <br />
              <textarea
                autofocus
                maxlength="500"
                v-model="feedbackMessage"
                placeholder="Στείλε μας τις εντυπώσεις σου!"
              ></textarea>
              <div>
                <button
                  v-on:click="onFeedback(feedbackMessage)"
                  class="button-next"
                  :disabled="isDisabledSend"
                  type="button"
                  value="submit"
                >
                  <Span>Αποστολή</Span>
                </button>
              </div>
            </div>
          </div>
          <div v-else-if="rating !== null && rating < 9">
            <div v-if="reviewFeedback === null">
              <div v-if="rating < 9 && rating >= 6">
                <h1 class="review-title">
                  Χαιρόμαστε που είχες ευχάριστη εμπειρία.
                </h1>
                <h2 v-if="isLargeScreen" class="star-title">
                  • Τι θα μπορούσε να βελτιώσει την εμπειρία σου;
                </h2>
                <h2 v-else class="star-title">
                  • Τι θα μπορούσε να βελτιώσει <br />
                  την εμπειρία σου;
                </h2>
                <div v-if="!isLargeScreen">
                  <br />
                  <br />
                </div>
              </div>
              <!-- for rating 5 do not have a title -->
              <div v-else-if="rating === 5">
                <h1 class="review-title"></h1>
                <h2 class="star-title">• Τι δεν σου άρεσε;</h2>
              </div>

              <div v-else>
                <h1 class="review-title">
                  Ζητάμε συγνώμη για την εμπειρία που είχες μαζί μας.
                </h1>
                <h2 class="star-title">• Τι δεν σου άρεσε;</h2>
              </div>
              <div>
                <button
                  v-on:click="onFeedback('bad instractions')"
                  class="button-next"
                  type="button"
                  value="bad-instractions"
                  style="background-color: #f68b24"
                >
                  <Span
                    v-if="isLargeScreen"
                    style="font-size: 1.4rem; line-height: 24.35px !important"
                    >{{ isReturn === true ? 'Οδηγίες επιστροφής' : 'Οδηγίες παραλαβής' }}</Span
                  >
                  <Span v-else style="font-size: 1rem; line-height: 18.35px !important">{{
                    isReturn === true ? 'Οδηγίες επιστροφής' : 'Οδηγίες παραλαβής'
                  }}</Span>
                </button>
                <button
                  v-on:click="onFeedback('more time in')"
                  class="button-next"
                  type="button"
                  value="more-time-in"
                  style="background-color: #f68b24"
                >
                  <Span
                    v-if="isLargeScreen"
                    style="font-size: 1.1rem; line-height: 19.35px !important"
                    >{{
                      isReturn === true
                        ? 'Η θυρίδα να είναι διαθέσιμη μεγαλύτερο διάστημα στο Skroutz Point'
                        : 'Μεγαλύτερη παραμονή στο Skroutz Point'
                    }}</Span
                  >
                  <Span v-else style="font-size: 0.9rem; line-height: 16.35px !important">{{
                    isReturn === true
                      ? 'Η θυρίδα να είναι διαθέσιμη μεγαλύτερο διάστημα στο Skroutz Point'
                      : 'Μεγαλύτερη παραμονή στο Skroutz Point'
                  }}</Span>
                </button>
              </div>
              <div>
                <button
                  v-on:click="onFeedback('bad location')"
                  class="button-next"
                  type="button"
                  value="bad-location"
                  style="background-color: #f68b24"
                >
                  <Span
                    v-if="isLargeScreen"
                    style="font-size: 1.2rem; line-height: 25.35px !important"
                    >Τοποθεσία του Skroutz Point</Span
                  >
                  <Span v-else style="font-size: 1rem; line-height: 22.35px !important"
                    >Τοποθεσία του Skroutz Point</Span
                  >
                </button>
                <button
                  v-on:click="onFeedback('custom')"
                  class="button-next"
                  type="button"
                  value="custom"
                  style="background-color: #f68b24"
                >
                  <Span
                    v-if="isLargeScreen"
                    style="font-size: 1.7rem; line-height: 105.35px !important"
                    >Κάτι άλλο</Span
                  >
                  <Span v-else style="font-size: 1rem; line-height: 48.35px !important"
                    >Κάτι άλλο</Span
                  >
                </button>
              </div>
            </div>
            <div
              v-else-if="
                reviewFeedback === 'custom' ||
                  reviewFeedback === 'bad instractions' ||
                  reviewFeedback === 'bad location'
              "
            >
              <div v-if="rating < 9 && rating >= 5">
                <h1 class="review-title">
                  Χαιρόμαστε που είχες ευχάριστη εμπειρία.
                </h1>
              </div>
              <div v-else>
                <h1 class="review-title">
                  Ζητάμε συγνώμη για την εμπειρία που είχες μαζί μας.
                </h1>
              </div>
              <h2 class="star-title">• Τι θα μας πρότεινες να βελτιώσουμε;</h2>
              <textarea
                autofocus
                maxlength="500"
                v-model="feedbackMessage"
                placeholder="Στείλε μας τις παρατηρήσεις σου"
              ></textarea>
              <div>
                <button
                  v-on:click="onFeedback(feedbackMessage)"
                  class="button-next"
                  :disabled="isDisabledSend"
                  type="button"
                  value="submit"
                >
                  <Span>Αποστολή</Span>
                </button>
              </div>
            </div>
            <div v-else-if="confirmedSend === true">
              <div class="container">
                <h1 class="review-title">
                  Ευχαριστούμε πολύ για το χρόνο σου!
                </h1>
                <router-link to="/"
                  ><button
                    class="button-next"
                    type="button"
                    value="submit"
                    style="background-color: #f68b24"
                  >
                    <Span>Επιστροφή</Span>
                  </button></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="exists === true">
    <div class="container">
      <h1 class="review-title">Ευχαριστούμε για την αξιολόγηση σου!</h1>
      <router-link to="/"
        ><button class="button-next" type="button" value="submit" style="background-color: #f68b24">
          <Span>Επιστροφή</Span>
        </button></router-link
      >
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';
import axios from 'axios';
export default {
  name: 'ReviewBody',
  components: {
    StarRating,
  },
  props: {
    exists: {
      type: Boolean,
      required: true,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    voucher: {
      type: String,
      required: true,
    },
    isLockerOrder: {
      type: Boolean,
      required: true,
    },
    isReturn: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ratingScale: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      rating: null,
      reviewOption: '',
      hoveredRating: null,
      confirmedRating: false,
      confirmedTipSelection: false,
      reviewFeedback: null,
      feedbackMessage: '',
      confirmedSend: false,
      ratingExplanation: this.isLockerOrder
        ? {
            0: 'Καθόλου πιθανό 😡',
            1: 'Καθόλου πιθανό 😡',
            2: 'Λίγο πιθανό 😞',
            3: 'Λίγο πιθανό 🙁',
            4: 'Λίγο πιθανό 🫤',
            5: 'Λίγο πιθανό 😐',
            6: 'Λίγο πιθανό 😐',
            7: 'Πιθανό 🙂',
            8: 'Πιθανό 🙂',
            9: 'Πολύ πιθανό 😃',
            10: 'Πολύ πιθανό 🤩',
          }
        : {
            1: 'Απαίσια 😡',
            2: 'Κακή 🙁',
            3: 'Μέτρια 😐',
            4: 'Καλή 🙂',
            5: 'Πολύ Καλή 🤩',
          },
      isLargeScreen: false, // Adjust the breakpoint as needed
    };
  },
  computed: {
    isDisabled() {
      return this.rating === null;
    },

    isDisabledSend() {
      return this.feedbackMessage === null || this.feedbackMessage.length < 1;
    },

    hasFinishedReviewing() {
      if (this.rating === null) {
        return false;
      } else if (
        (this.rating > 3 && this.confirmedRating === true && this.isLockerOrder !== true) ||
        (this.rating >= 9 &&
          this.confirmedRating === true &&
          this.confirmedSend === true &&
          this.isLockerOrder === true)
      ) {
        return true;
      } else if (
        (this.rating <= 3 && this.confirmedSend === true && this.isLockerOrder !== true) ||
        (this.rating < 9 && this.confirmedSend === true && this.isLockerOrder === true)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    hasFinishedReviewing: function(newVal) {
      if (newVal === true) {
        this.postReview();
      }
    },
  },
  mounted() {
    this.handleResize(); // Call handleResize once for the initial value
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    onRatingHover(rating) {
      if (rating === null) {
        this.hoveredRating = null;
      } else {
        this.hoveredRating = +rating;
      }
    },
    handleResize() {
      this.isLargeScreen = window.innerWidth > 450; // Adjust the breakpoint as needed
    },

    setRating(rating) {
      this.rating = +rating;
    },

    confirmRating() {
      this.confirmedRating = true;
    },

    confirmTipSelection() {
      this.confirmedTipSelection = true;
    },
    onFeedback(value) {
      this.reviewFeedback = value;
      if (this.isLockerOrder === true && this.reviewOption === '') {
        if (this.rating < 9) {
          this.reviewOption = value;
        } else {
          this.reviewOption = 'favorite experience';
        }
      }
      if (
        (this.reviewFeedback !== 'custom' && this.isLockerOrder !== true) ||
        ((this.reviewFeedback === 'more time in' || this.reviewFeedback !== this.reviewOption) &&
          this.isLockerOrder === true)
      ) {
        this.confirmedSend = true;
      }
    },

    postReview() {
      if (this.hasFinishedReviewing === true) {
        const body = {
          reviewOption: this.reviewOption,
          rating: this.rating,
          feedback: this.reviewFeedback,
        };
        axios
          .post(
            `${process.env.VUE_APP_COURIER_API}/user/review/${this.voucher}`,
            // `http://localhost:8000/user/review/${this.voucher}`,
            body,
          )
          .catch(err =>
            console.error({
              status: err?.response?.status,
              message: err.response?.data?.error_description,
            }),
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.review-title {
  height: 50px;
  line-height: 34px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 26px;
  width: 490px;
  letter-spacing: 0px;
}
.button-container {
  margin-top: 46px;
  margin-bottom: 16px;
  margin-left: auto;
}
.rating-explanation {
  padding-top: 10px;
  padding-left: 10px;
  font-size: 18px;
  font-weight: 400;
  position: absolute;
}

.star-title {
  height: 30px;
  line-height: 30px;
  margin-top: 70px;
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 22px;
  width: 490px;
  letter-spacing: 0px;
}

textarea {
  :placeholder {
    opacity: 0.6;
  }
  border-radius: 10px;
  height: 15vh;
  width: 45%;
  resize: none;
}

textarea :hover,
:focus,
:active {
  border-color: #f68b24;

  &::placeholder {
    opacity: 0.4;
  }
}

@media (max-width: 733px) {
  .review-title {
    line-height: 29px;
    height: 46px;
    font-size: 19px;
    width: 328px;
  }
  .star-title {
    font-size: 18px;
    margin-top: 35px;
    margin-bottom: 2px;
  }
  button {
    font-size: 16px;
  }

  textarea {
    width: 95%;
  }
}

@media only screen and (max-width: 768px) {
  button {
    display: inline-block;
    border-radius: 15px;
    border: none;
    color: #ffffff;
    text-align: center;
    font-size: 24px;
    width: 180px;
    transition: all 0.5s;
    cursor: pointer;
    margin-top: 20px;
    margin-right: 25px;
    vertical-align: top;
  }
}

/* Media query for screens with a width greater than 768 pixels (typical for computers) */
@media only screen and (min-width: 769px) {
  button {
    display: inline-block;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    text-align: center;
    font-size: 24px;
    width: 180px;
    transition: all 0.5s;
    cursor: pointer;
    margin-top: 40px;
    margin-right: 25px;
    vertical-align: top;
  }
}

.button-next {
  background-color: #51b74a;
}

.button-no {
  background-color: #eb2e2b;
}

button span {
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-block;
  position: relative;
  transition: 0.2s;
}

.button-next span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 8px;
  right: -20px;
  transition: 0.2s;
}

.button-no span:after {
  content: '\2715';
  position: absolute;
  opacity: 0;
  top: 8;
  right: -20px;
  transition: 0.2s;
}

button:hover span {
  padding-right: 25px;
}

button:disabled {
  background-color: #d3d3d3;
  pointer-events: none;
  cursor: not-allowed;
}

button:hover span:after {
  opacity: 1;
  right: 0;
}

.bold-text {
  font-weight: bold;
}
.indent {
  display: inline-block;
  margin-left: 20px; /* Adjust the margin as needed for the desired indentation */
}

.rating-container {
  display: flex;
  gap: 10px;
  font-size: 24px;
}

@media only screen and (max-width: 768px) {
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
  }
}

/* Media query for screens with a width greater than 768 pixels (typical for computers) */
@media only screen and (min-width: 769px) {
  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
  }
}

.circle:hover {
  background-color: #f68b24;
}
.circle.selected {
  background-color: #f68b24;
}
.rating-explanation {
  font-size: 18px;
  margin-top: 10px;
}
</style>
